
import { defineComponent, ref } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import ApiService from "@/core/services/ApiService";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "RoleDeleteConfirm",
  components: {},
  props: ["id", "name"],
  setup(props) {
    const newTargetModalDel = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    const router = useRouter();

    const submit = () => {
      loading.value = true;
      let idToDel = props.id;
      ApiService.delete(`api/roles/${idToDel}`)
        .then(({ data }) => {
          if (data.success) {
            closeModal();
            Swal.fire({
              text: "¡Rol eliminado!",
              icon: "success",
              allowOutsideClick: false,
              buttonsStyling: false,
              confirmButtonText: "Volver al listado",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            }).then(() => {
              loading.value = false;
              router.push({
                name: "roles",
              });
            });
          }
        })
        .catch(({ response }) => {
          if (response !== undefined) {
            let errors = "";
            for (let key in response.data.errors) {
              let error = response.data.errors[key];
              for (let keyError in error) {
                let errorMsg = error[keyError];
                errors += errorMsg + "</br>";
              }
            }
            loading.value = false;
            Swal.fire({
              title: `!${response.data.message}!</br> ${errors}`,
              icon: "warning",
              buttonsStyling: false,
              confirmButtonText: "Aceptar",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            }).then((errors) => {
              return false;
            });
          }
        });
    };

    const closeModal = () => {
      hideModal(newTargetModalDel.value);
    };

    return {
      submit,
      loading,
      newTargetModalDel,
      closeModal,
    };
  },
});
