
import ApiService from "@/core/services/ApiService";
import { defineComponent, ref, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useRoute } from "vue-router";
import KTDatatable from "@/components/kt-datatable/KTDatatable.vue";
import moment from "moment";
import ModalRoleForm from "@/components/roles/RoleForm.vue";
import ModalRoleDeleteConfirm from "@/components/roles/RoleDeleteConfirm.vue";

export default defineComponent({
  name: "RoleDetail",
  components: { KTDatatable, ModalRoleForm, ModalRoleDeleteConfirm },
  setup() {
    moment.locale("es");
    let roleData: any = ref([]);
    const route = useRoute();
    const tableHeader = ref([
      {
        name: "Nombre del usuario",
        key: "name",
      },
      {
        name: "Correo electrónico",
        key: "email",
      },
      {
        name: "Asignado el",
        key: "createdAt",
      },
    ]);
    const loading = ref(true);
    const roleEdit = ref([]);
    const typeForm = ref("");
    let idRole = route.params.id;
    let roleName = ref("");

    const getRoleData = () => {
      roleData.value.length = 0;
      getRole(idRole);
    };

    const getRole = (id) => {
      ApiService.get(`api/roles/${id}`)
        .then(({ data }) => {
          if (data.success) {
            let response = data.data.role;
            for (const key in response[0].role_list_users) {
              response[0].role_list_users[key].created_at_user = moment(
                response[0].role_list_users[key].created_at_user
              ).format("L");
            }
            roleData.value.push(response[0]);
          }
          loading.value = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    };

    const editRole = (role) => {
      roleEdit.value = { ...role };
      typeForm.value = "detailEdit";
    };

    const deleteRole = (name) => {
      roleName.value = name;
    };

    onMounted(() => {
      setCurrentPageBreadcrumbs("Detalle del rol", ["Roles y permisos"]);
      getRole(idRole);
    });

    return {
      roleData,
      tableHeader,
      loading,
      idRole,
      editRole,
      roleEdit,
      typeForm,
      getRoleData,
      roleName,
      deleteRole,
    };
  },
});
